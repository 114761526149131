<template>
  <div class="row">
    <div class="col-lg-9 order-lg-0 order-2">
      <div
        v-if="
          displayApprenticeList &&
          (trainingCourse.isStudeaManager ||
            this.user.roles.includes('ROLE_STUDEA_ADMIN'))
        "
        class="widget"
      >
        <div class="widget-heading">
          <h2 class="textStyle">{{ $t("absence.apprentice_list") }}</h2>
          <button
            class="btn-icon"
            type="button"
            @click="displayApprenticeList = false"
          >
            <FontAwesomeIcon icon="fa-light fa-xmark" />
          </button>
        </div>
        <div class="widget-content">
          <TriadUsersListComponent
            :preventClick="true"
            :search="true"
            :trainingCourse="trainingCourse"
            @triadClick="triadClick"
          />
        </div>
      </div>
      <div class="widget">
        <CalendarBlockComponent
          :key="key"
          :editable="isStudeaManager"
          :events="filteredEvents"
          :loading="loading"
          :paste="paste"
          @add="addEvent"
          @addAbsence="addAbsence"
          @copy="copyEvent"
          @cut="cutEvent"
          @dayClicked="dayClicked"
          @delete="deleteEvent"
          @edit="editEvent"
          @eventMoved="eventMoved"
          @paste="pasteEvent"
          @show="show"
          @typeClicked="typeClicked"
          @viewChanged="viewChanged"
        />
      </div>
    </div>
    <div class="col-lg-3 order-lg-2 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <div
            v-if="
              user &&
              user.roles &&
              user.roles.includes('ROLE_STUDEA_ADMIN') &&
              $route.params.trainingCourseId
            "
            class="widget"
          >
            <div class="widget-content">
              <button
                class="btn btnStyle btn-success w-100"
                @click="openConfig"
                v-access:[permissions]="accessManager().EDIT"
              >
                <FontAwesomeIcon icon="fa-light fa-cog" class="me-2" />
                Configuration
              </button>
            </div>
          </div>
          <ActionsBlock
            v-if="!isStudeaManager"
            :actions="userActions"
            :mobileCollapsable="true"
            @planningExport="planningExport"
          />
          <div v-if="isStudeaManager">
            <ActionsBlock
              :actions="planningActions"
              :collapsable="true"
              :title="$t('absence.actions.planning')"
              collapseId="planning"
              @planningExport="planningExport"
            />
            <ActionsBlock
              :actions="absenceActions"
              :collapsable="true"
              :title="$t('absence.actions.absences')"
              collapseId="absence"
            />
            <ActionsBlock
              :actions="optionsActions"
              :collapsable="true"
              :title="$t('absence.actions.options')"
              collapseId="options"
              @optionDetail="optionDetail"
              @optionDisplay="optionDisplay"
            />
          </div>
        </div>
        <div ref="details" class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">{{ details.title }}</h2>
          </div>
          <div class="widget-content">
            <div
              v-for="event in details.events"
              :key="event.id"
              :class="'eventItem --' + event.type"
              @click="
                !isStudeaManager ? getJustifyForm(event.id, event.type) : null
              "
            >
              <div
                class="event__heading d-flex justify-content-between fst-italic"
              >
                <span class="event__date">{{ $d(event.start_date) }}</span>
                <span class="event__date">{{ event.hours }}</span>
              </div>
              <h3 class="textStyle">
                {{ event.type === 3 ? $t("absence.absence") : event.title }}
              </h3>
              <div v-if="event.subject" class="event__subject">
                {{ event.subject }}
              </div>
              <div v-if="event.former" class="event__subject">
                {{ event.teacher }}
              </div>
              <div v-if="event.classroom" class="event__subject">
                {{ event.classroom }}
              </div>
              <button
                v-if="!event.justified && event.type === 3"
                class="badge bg-danger"
              >
                {{ $t("absence.not_justify") }}
              </button>
            </div>
            <div v-if="!Object.keys(details.events).length">
              {{ $t("There are no events on this day.") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TriadUserDetailComponent :id="triadId" @hidden="hidden" />
  <ModalFormComponent
    :models="models"
    :title="modalTitle"
    :hideSubmit="modalType !== 'justify' && modalType !== 'config'"
    @submit="submitModal"
    @hidden="hideModal"
  />
</template>

<script>
import CalendarBlockComponent from "@/components/blocks/CalendarBlockComponent.vue";
import { mapState } from "pinia";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import TriadUsersListComponent from "@/components/triad/TriadUsersListComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import TriadUserDetailComponent from "@/components/triad/TriadUserDetailComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { absenceManager } from "@/services/absence/absence-manager";
import { accessManager } from "@/services/security/access-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "AbsenceView",
  components: {
    ModalFormComponent,
    TriadUserDetailComponent,
    FontAwesomeIcon,
    TriadUsersListComponent,
    ActionsBlock,
    CalendarBlockComponent,
  },
  data() {
    return {
      loading: false,
      today: new Date(),
      pageTitle: "",
      displayApprenticeList: false,
      triadId: 0,
      actionEventType: null,
      actionEvent: null,
      paste: false,
      key: 0,
      events: [],
      filteredEvents: [],
      models: [],
      isStudeaManager: false,
      params: {},
      fetching: false,
      modalTitle: "",
      modalType: "",
      absenceId: null,
      userActions: [
        {
          translation: this.$t("absence.export_planning"),
          icon: "download",
          type: "primary",
          action: "planningExport",
          cfaStyle: true,
        },
        {
          translation: this.$t("absence.list_absences"),
          icon: "users",
          type: "primary",
          action: "absenceListEvent",
          cfaStyle: true,
          actionType: "router",
          params: { type: "absence" },
        },
      ],
      planningActions: [
        {
          translation: this.$tc("global.add.title", 1, {
            name: this.$tc("studea_manager.planning.title", 1).toLowerCase(),
          }),
          icon: "plus",
          type: "primary",
          action: this.$route.params.trainingCourseId
            ? "studeaManagerPlanningAdd"
            : "absenceAddEditEvent",
          cfaStyle: true,
          actionType: "router",
          params: { type: "event", alias: "planning" },
          query: {
            backPath: this.$route.params.trainingCourseId
              ? "studeaManagerAbsenceList"
              : "",
          },
          access: accessManager.EDIT,
        },
        {
          translation: this.$t("absence.list_planning"),
          icon: "users",
          type: "primary",
          action: "studeaManagerPlanningList",
          cfaStyle: true,
          actionType: "router",
          params: { type: "event", alias: "planning" },
          access: accessManager.VIEW,
        },
        {
          translation: this.$tc("global.import.title", 1, {
            name: this.$tc("studea_manager.planning.title", 2).toLowerCase(),
          }),
          icon: "calendar",
          type: "primary",
          action: this.$route.params.trainingCourseId
            ? "studeaManagerPlanningImport"
            : "absenceImportEvent",
          cfaStyle: true,
          actionType: "router",
          params: { type: "event", alias: "planning" },
          access: accessManager.EDIT,
        },
        {
          translation: this.$t("absence.export_planning"),
          icon: "upload",
          type: "primary",
          action: "planningExport",
          cfaStyle: true,
        },
      ],
      absenceActions: [],
      optionsActions: [],
      details: {
        title: "25/04/2023",
        events: [],
      },
    };
  },
  watch: {
    trainingCourses() {
      this.setStudeaManager();
      this.key++;
    },
    triad() {
      this.init();
      this.initUserActions();
    },
    apprenticeProfileLabel() {
      this.initOptionActions();
    },
    trainingCourse() {
      this.isPresenceNotificationAction =
        this.trainingCourse.useNotificationPresence;
      this.initAbsenceActions();
    },
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
    }),
  },
  mounted() {
    if (this.trainingCourse && this.trainingCourse.useNotificationPresence) {
      this.isPresenceNotificationAction =
        this.trainingCourse.useNotificationPresence;
    }
    this.initAbsenceActions();
    this.initUserActions();
    if (this.apprenticeProfileLabel.titlePlural) {
      this.initOptionActions();
    }
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_ABSENCE,
    );
  },
  methods: {
    accessManager() {
      return accessManager;
    },

    initAbsenceActions() {
      this.absenceActions = [
        {
          translation: this.$tc("global.add.title", 2, {
            name: this.$tc("studea_manager.absence.title", 1).toLowerCase(),
          }),
          icon: "plus",
          type: "primary",
          action: this.$route.params.trainingCourseId
            ? "studeaManagerAbsenceAdd"
            : "absenceAddEditEvent",
          cfaStyle: true,
          actionType: "router",
          params: { type: "absence", alias: "absence" },
          query: {
            backPath: this.$route.params.trainingCourseId
              ? "studeaManagerAbsenceList"
              : "",
          },
          access: accessManager.EDIT,
        },
        {
          translation: this.$t("absence.list_absences"),
          icon: "users",
          type: "primary",
          action: "studeaManagerAbsenceAbsenceList",
          cfaStyle: true,
          actionType: "router",
          params: { type: "absence", alias: "absence" },
          access: accessManager.VIEW,
        },
        {
          translation: this.$tc("global.import.title", 1, {
            name: this.$tc("studea_manager.absence.title", 2).toLowerCase(),
          }),
          icon: "calendar",
          type: "primary",
          action: this.$route.params.trainingCourseId
            ? "studeaManagerAbsenceImport"
            : "absenceImportEvent",
          cfaStyle: true,
          actionType: "router",
          params: { type: "absence", alias: "absence" },
          access: accessManager.EDIT,
        },
      ];
      if (this.isPresenceNotificationAction) {
        this.absenceActions.push({
          translation: this.$t("absence.notification"),
          icon: "bell",
          type: "primary",
          // TODO Restore comment and make a correction
          //action: "absenceAddEditEvent",
          cfaStyle: true,
          actionType: "router",
          //params: { type: "notification" },
        });
      }
    },

    initOptionActions() {
      this.optionsActions = [
        {
          translation:
            this.$t("absence.display_apprentice_list") +
              " " +
              this.apprenticeProfileLabel &&
            this.apprenticeProfileLabel.titlePlural
              ? this.apprenticeProfileLabel.titlePlural.toLowerCase()
              : "",
          icon: "eye",
          type: "primary",
          action: "optionDisplay",
          cfaStyle: true,
        },
        {
          translation: this.$t("absence.training_detail"),
          icon: "circle-info",
          type: "primary",
          action: "optionDetail",
          cfaStyle: true,
        },
      ];
    },

    dayClicked(day) {
      let date = this.$d(day);
      let events = [];
      if (this.events) {
        Object.values(this.events).forEach((event) => {
          if (this.$d(event.start_date) === date) {
            events.push(event);
          }
        });
      }

      this.details = {
        title: this.$d(day, { key: "long_date" }),
        events: events,
      };

      if (window.innerWidth < 992) {
        window.scrollTo({
          behavior: "smooth",
          top:
            this.$refs.details.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            100,
        });
      }
    },

    initUserActions() {
      this.userActions = [
        {
          translation: this.$t("absence.export_planning"),
          icon: "download",
          type: "primary",
          action: "planningExport",
          cfaStyle: true,
        },
        {
          translation: this.$t("absence.list_absences"),
          icon: "users",
          type: "primary",
          action: "absenceListEvent",
          cfaStyle: true,
          actionType: "router",
          params: { type: "absence" },
        },
      ];
      if (
        this.triad.trainingCourse &&
        this.triad.trainingCourse.usePresenceAttestation
      ) {
        this.userActions.push({
          translation: this.$t("absence.list_attendance_certificate"),
          icon: "eye",
          type: "primary",
          action: "attendanceCertificateListView",
          actionType: "router",
          cfaStyle: true,
        });
      }
    },

    init() {
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_ABSENCE,
      );
      this.dayClicked(this.today);
      this.setStudeaManager();

      this.loading = true;
      if (this.$route.params.trainingCourseId) {
        studeaManagerManager
          .list(
            "planning/event",
            this.$route.params.trainingCourseId,
            false,
            this.params,
          )
          .then((data) => {
            this.events = data;
            this.filteredEvents = this.events;
            this.fetching = false;
            this.loading = false;
          });
      } else {
        if (this.triad && this.triad.id) {
          this.params["includePlanning"] = 1;
          absenceManager
            .loadEventList(this.triad.id, this.params)
            .then((data) => {
              this.events = data;
              this.filteredEvents = this.events;
              this.fetching = false;
              this.loading = false;
            });
        }
      }
    },

    getJustifyForm(id, type) {
      this.loading = true;
      if (this.trainingCourse && this.trainingCourse.id) {
        this.modalTitle =
          type === 3 ? this.$t("absence.justify") : this.$t("absence.planning");
        this.modalType = type === 3 ? "justify" : "planning";
        this.absenceId = id;
        absenceManager
          .justifyForm(id, type, this.trainingCourse.id)
          .then((models) => {
            this.loading = false;
            this.models = models;
          });
      }
    },

    putJustifyForm() {
      if (this.trainingCourse && this.trainingCourse.id) {
        const result = formManager.processForm(
          this.models,
          null,
          this.absenceId,
        );
        absenceManager
          .putJustifyForm(this.absenceId, result, this.trainingCourse.id)
          .then(() => {
            this.models = [];
            this.absenceId = null;
          });
      }
    },

    openConfig() {
      this.modalTitle = this.$t("studea_manager.absence.config.title");
      this.modalType = "config";
      studeaManagerManager
        .form("absence/config", this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
        });
    },

    submitModal() {
      if (this.modalType === "config") {
        this.submitConfig();
      } else if (this.modalType === "justify") {
        this.putJustifyForm();
      }
    },

    submitConfig() {
      let response = formManager.processForm(this.models, false);
      this.loading = true;

      let urlCall =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/generic/training-course/post/" +
        this.$route.params.trainingCourseId;
      apiConnection.put(urlCall, response).then((data) => {
        if (data && !data.hasError) {
          notificationManager.showNotification(
            "success",
            this.$t("studea_manager.absence.config.success"),
          );
          this.isPresenceNotificationAction =
            data.responses[0].object.useNotificationPresence;
          this.init();
          this.initAbsenceActions();
        } else if (data.responses && data.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
        }
        this.loading = false;
      });
    },

    hideModal() {
      this.models = [];
    },

    setStudeaManager() {
      this.isStudeaManager = studeaManagerManager.isStudeaManager(
        this.$route.params.trainingCourseId,
      );
    },

    filterEvents(type) {
      this.filteredEvents =
        typeof type === "number"
          ? this.events.filter((item) => item.type === type)
          : this.events;
    },

    triadClick(id) {
      this.triadId = id;
    },

    typeClicked(payload) {
      const $this = this;
      let events = [];
      if ($this.triad.timetable) {
        Object.values($this.triad.timetable).forEach(function (event) {
          if (event.type === payload.type) {
            events.push(event);
          }
        });
      }

      this.filterEvents(payload.type);

      if (!payload.type) {
        this.dayClicked(this.today);
      } else {
        $this.details = {
          title: payload.title,
          events: events,
        };
      }
    },

    editEvent(event) {
      this.$router.push({
        name:
          event.extendedProps.type === "absence"
            ? "studeaManagerAbsenceEdit"
            : "studeaManagerPlanningEdit",
        params: {
          id: event.id,
          alias:
            event.extendedProps.type === "absence" ? "absence" : "planning",
        },
        query: {
          backPath: "studeaManagerAbsence",
        },
      });
    },

    addEvent(date) {
      this.$router.push({
        name: "studeaManagerPlanningAdd",
        params: { alias: "planning" },
        query: {
          set: JSON.stringify([
            {
              name: "startDate",
              value: date,
            },
          ]),
          backPath: "studeaManagerAbsence",
        },
      });
    },

    addAbsence(date) {
      this.$router.push({
        name: "studeaManagerAbsenceAdd",
        params: { alias: "absence" },
        query: {
          set: JSON.stringify([
            {
              name: "startDate",
              value: date,
            },
          ]),
          backPath: "studeaManagerAbsence",
        },
      });
    },

    deleteEvent(event) {
      this.loading = true;
      studeaManagerManager
        .remove(
          event.extendedProps.type === "absence" ? "absence" : "planning",
          this.$route.params.trainingCourseId,
          event.id,
          event.extendedProps.type === "absence" ? 2 : 1,
        )
        .then(() => {
          this.init();
        });
    },

    copyEvent(event) {
      this.actionEventType = "copy";
      this.actionEvent = event;
      this.paste = true;
    },

    cutEvent(event) {
      this.actionEventType = "cut";
      this.actionEvent = event;
      this.paste = true;
    },

    pasteEvent(date) {
      this.loading = true;
      studeaManagerManager
        .copy(
          this.actionEvent._def.extendedProps.type === "absence"
            ? "absence"
            : "planning",
          this.$route.params.trainingCourseId,
          this.actionEvent.id,
          this.actionEvent._def.extendedProps.type === "absence" ? 2 : 1,
          false,
          { startDate: date, action: this.actionEventType },
        )
        .then(() => {
          this.init();
        });

      this.actionEventType = null;
      this.actionEvent = null;
      this.paste = false;
    },

    viewChanged(payload) {
      if (
        this.params.startDate !== payload.startStr ||
        this.params.endDate !== payload.endStr
      ) {
        this.params.startDate = payload.startStr;
        this.params.endDate = payload.endStr;
        this.init();
      }
    },

    eventMoved(payload) {
      this.loading = true;
      studeaManagerManager
        .move(
          payload.event.extendedProps.type === "absence"
            ? "absence"
            : "planning",
          this.$route.params.trainingCourseId,
          payload.event.id,
          null,
          payload.event.extendedProps.type === "absence" ? 2 : 1,
          { startDate: payload.event.startStr },
        )
        .then(() => {
          this.init();
        });
    },

    show(payload) {
      const id = payload._def.publicId;
      const type = payload.extendedProps.type;
      const path =
        type === "event"
          ? "studeaManagerPlanningEdit"
          : "studeaManagerAbsenceEdit";
      const alias = type === "event" ? "planning" : "absence";
      this.$router.push({
        name: path,
        params: { id: id, alias: alias },
      });
    },

    planningExport() {
      notificationManager.showNotification(
        "success",
        this.$t("absence.export_planning_success"),
      );
    },

    optionDisplay() {
      this.displayApprenticeList = !this.displayApprenticeList;
      this.optionsActions[0].icon = this.displayApprenticeList
        ? "eye-slash"
        : "eye";
      this.optionsActions[0].translation = this.displayApprenticeList
        ? "absence.hide_apprentice_list"
        : "absence.display_apprentice_list";
    },

    optionDetail(action) {
      console.log(action);
    },

    hidden() {
      this.triadId = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.eventItem {
  border-left: 5px solid $light-grey;
  padding: 3px 3px 3px 10px;
  cursor: pointer;

  &.--0 {
    border-color: $success;
  }

  &.--1 {
    border-color: $primary;
  }

  &.--3 {
    border-color: $warning;
  }

  &:hover,
  &:focus {
    background: $m-color_2;
    @include dark-theme {
      background-color: $dark-grey;
    }
  }
}

.eventItem + .eventItem {
  margin-top: 10px;
}
</style>
