export default {
  admin: {
    help_settings: "Parámetros de la ayuda",
    help_settings_success: "Parámetros de la ayuda modificados correctamente",
    lastEmail: "Enlace del último correo enviado",
    generatePassword: "Volver a generar y enviar la contraseña",
    switchUser: "Representar a {name}",
    switchAdmin: "Está representando a",
    modify: "Modificar {title}",
    delete: "Eliminar {title}",
    add: "Añadir",
    name: "Apellido",
    title: "Título",
    year: "Año",
    trainingOpeningStart: "Inicio de la inscripción en la formación",
    trainingOpeningEnd: "Fin de la inscripción en la formación",
    level: "Nivel",
    registrationDate: "Fecha de registro",
    active: "Activo",
    management_of: "Gestión de",
    contents: "contenidos",
    users: "Usuarios",
    trainings: "Formaciones",
    modules: "Módulos",
    module: "Módulo",
    code: "Código",
    error: "Se ha producido un error. Vuelva a intentarlo más tarde",
    lastName: "Apellido",
    firstName: "Nombre",
    profile: "Perfil",
    embody: "Representar a",
    visible: "Visible",
    popup: "Ventana emergente",
    visibleHomeLea: "Visible",
    endDate: "Fecha de finalización",
    startDate: "Fecha de inicio",
    contractTypeColor: "Color del contrato",
    none_duplicated_user: "Ningún usuario para combinar",
    skill: {
      title: "Competencia",
    },
    skillCategory: {
      title: "Categoría",
    },
    skill_session: {
      generate: "Generar períodos",
      empty:
        "Debe introducir el número de períodos que quiere crear en la configuración del módulo para poder configurarlos",
      generation_success: "Períodos generados correctamente",
      existing_session:
        "Está a punto de eliminar periodos existentes. ¿Seguro que quiere continuar?",
    },
    skillReferential: {
      title: "Referencia de competencias",
      edit: "Modificar la referencia",
    },
    navigation: {
      users: "Usuarios",
      trainings: "Formaciones",
      modules: "Módulos",
      settings: "Configuración",
      general: "General",
      steering: "Dirección",
    },
    home: {
      title: "Configuración general",
      title2: "Vista de conjunto",
      title3: "Sincronización automática",
      title4: "Estadísticas generales",
      active: "Activado",
      unique_connection: "conexión única",
      apprentices_mentor: "Alumnos y mentores",
    },
    log: {
      title: ({ plural }) => plural(["Registro", "Registros"]),
      view: "Ver los datos modificados",
      modify_field: "Campo modificado",
      old_data: "Dato antiguo",
      data: "Nuevo dato",
    },
    mail: {
      title: ({ plural }) => plural(["Correo", "Correos"]),
      view: "Ver el contenido del correo",
      recipient: "Destinatario:",
      status: "Estado de envío:",
      sent_on: "Enviado el:",
      open_on: "Abierto el:",
    },
    role: {
      title: ({ plural }) => plural(["Función", "Funciones"]),
    },
    triad: {
      title: ({ plural }) => plural(["Trinomio", "Trinomios"]),
    },
    studeaUser: {
      title: ({ plural }) => plural(["Usuario", "Usuarios"]),
    },
    user: {
      title: ({ plural }) => plural(["Usuario", "Usuarios"]),
      actions: {
        keep_user: "Guardar",
        delete_user: "Eliminar",
        unselect_user: "Deseleccionar este usuario",
        reset_password: {
          success:
            "El enlace de restablecimiento de la contraseña se ha enviado al usuario",
        },
      },
      search_user_by_name_or_mail: "Buscar un usuario por nombre o e-mail",
      import_users: "Importar usuarios",
      profile_config: "Configuración de perfiles",
      merge: "Fusión de usuarios",
      duplicated_user_count: "usuarios duplicados a combinar",
      merge_duplicated_user_confirmation:
        "¿Seguro que quiere combinar estos usuarios?",
      merge_success: "Combinación llevada a cabo correctamente",
      no_double_user: "Ningún duplicado encontrado",
      mail_address: "Dirección de correo electrónico",
      already_connected: "Ya se ha conectado al libro",
      yes: "Sí",
      no: "No",
      row: "Línea",
      reference_user: "Usuario de referencia",
      duplicated_user: "Duplicados",
      reset_choices: "Restablecer selecciones",
      options: "Opciones",
      account_creation: "Creación de la cuenta",
      username: "Nombre de usuario",
      external_id: "ID externo",
      birth_date: "Fecha de nacimiento",
      send_credential_back_to_user:
        "Volver a enviar las credenciales al usuario afectado",
      reference_user_is_not_duplicate:
        "El usuario de referencia no es un duplicado.",
      keep: "Guardar",
      delete: "Eliminar",
      export: "Exportar",
      send: "Registrar",
      profile_title: "Nombres de perfiles de usuario",
      users_profile_title_list: "Lista de nombres de perfiles de usuario",
      modify_profile: "Modificación del nombre",
      modification_succes: "Perfil modificado correctamente",
      no_mail_title: "Ningún enlace",
      no_mail:
        "Este usuario todavía no ha recibido ningún enlace de restablecimiento de la contraseña.",
      profile_user_info:
        "Crear los diferentes perfiles de responsable de libro con derechos específicos, que podrán asociarse después a los usuarios. Ejemplo: Gestor de asistencia, responsable pedagógico...",
      role: {
        NONE: "No",
        ALL: "Sí",
        VIEW: "Ver",
        EDIT: "Modificar",
        DELETE: "Eliminar",
      },
    },
    profile_label: {
      titleAbbreviation: "Abreviatura",
      titlePlural: "Título en plural",
      titleWithArticle: "Título con artículo",
    },
    training: {
      title: ({ plural }) => plural(["Formación", "Formaciones"]),
      ufa_add_test: "Crear una formación de prueba",
      genere_triad_test: "Generar tríos de prueba",
      generate_triad_test_confirmation:
        "¿Seguro que quiere crear tríos de prueba en todas las formaciones? ",
      genere_triad_test_success: "Tríos de prueba creados correctamente",
      genere_training_test_success: "Formación de prueba creada correctamente",
      import_triad: "Importar trinomios",
    },
    registration: {
      title: ({ plural }) => plural(["Inscripción", "Inscripciones"]),
    },
    trainingCourse: {
      title: ({ plural }) => plural(["Promoción", "Promociones"]),
      duplicate_on_next_year: "Duplicar la promoción en el año siguiente",
      duplicate: "Duplicar la promoción",
      delete: "Eliminar la promoción",
    },
    studeaManager: {
      title: ({ plural }) =>
        plural(["Responsable del cuaderno", "Responsables de cuaderno"]),
    },
    design: {
      title: "Diseño",
    },
    dataAgreement: {
      title: ({ plural }) =>
        plural(["Política de datos", "Políticas de datos"]),
    },
    institution: {
      title: ({ plural }) => plural(["Centro", "Centros"]),
      list: {
        title: "Centros activos",
      },
      export: {
        title: "Exportar las respuestas a los cuestionarios del año anterior",
      },
    },
    document: {
      title: ({ plural }) =>
        plural(["Documento público", "Documentos públicos"]),
    },
    documentCategory: {
      title: ({ plural }) =>
        plural(["Categoría de documento", "Categorías de documento"]),
    },
    helpDocument: {
      title: ({ plural }) => plural(["Ayuda online", "Ayudas online"]),
    },
    component: {
      title: ({ plural }) => plural(["Componente", "Componentes"]),
      export: {
        title: "Exportar las respuestas a los cuestionarios del año anterior",
      },
    },
    degree: {
      title: ({ plural }) =>
        plural(["Nivel de formación", "Niveles de formación"]),
    },
    contractType: {
      title: ({ plural }) => plural(["Tipo de contrato", "Tipos de contrato"]),
    },
    planContract: {
      title: ({ plural }) => plural(["Año de formación", "Años de formación"]),
      actions: {
        activate: {
          title: "Activar",
          success: "Año de formación activado correctamente",
        },
        activate_recruitment: {
          title: "Activar para contratación",
          success: "Activado para contratación correctamente",
        },
      },
    },
    titleRncp: {
      title: ({ plural }) => plural(["Título RNCP", "Títulos RNCP"]),
    },
    modulet: {
      title: ({ plural }) => plural(["Módulo", "Módulos"]),
      list: {
        title: "Gestión de módulos",
      },
      trainingCourse: {
        edit: {
          title: "Modificar las formaciones asociadas al módulo",
          success: "Formaciones asociadas al módulo modificadas correctamente",
        },
        displayActive: "Mostrar solo las formaciones asociadas al módulo",
      },
      config: {
        title: "Configuración del módulo",
      },
      skill_session: {
        title: "Generar períodos",
      },
      skill_referential: {
        title: "Referencia de competencias",
        list: "Referencias",
        edit: "Modificación de la referencia",
      },
    },
    template: {
      title: ({ plural }) => plural(["Plantilla", "Plantilles"]),
    },
    absenceReason: {
      title: ({ plural }) =>
        plural(["Motivo de ausencia", "Motivos de ausencia"]),
    },
    questionnaireConfig: {
      title: "Configuración del módulo de cuestionario",
    },
    ypareoConfig: {
      title: "Configuración del módulo Ypareo",
    },
    studeaManagerProfile: {
      title: ({ plural }) =>
        plural([
          "Perfil de responsable del cuaderno",
          "Perfiles de responsable del cuaderno",
        ]),
      all_access: "Activar todos los permisos",
    },
    historic: {
      title: "Historial",
    },
    statistic: {
      title: "Estadísticas",
      general: "General",
      questionnaires: "Cuestionarios",
      absence: "Ausencias",
      course: "Seguimiento del curso",
      updated_on: "actualizado el",
      indicators_generated:
        "Indicadores adicionales que se están generando actualmente",
    },
    export: {
      title: "Exportaciones",
      year: "Promociones",
      available_exports: "Exportaciones disponibles",
    },
    duplicate: {
      title: "Duplicados",
    },
    archive: {
      title: "Archivado",
    },
    news: {
      title: ({ plural }) => plural(["Noticia", "Noticias"]),
      news_list: "Lista de noticias",
      list: {
        title: "Noticias",
        uploaded_date: "Publicado el",
        see_more: "Ver más",
        see_all_news: "Recuperar todas las noticias",
        go_back_newslist: "Volver a la lista de noticias",
      },
    },
    import: {
      modele: "Modelo de archivo para descargar",
      triad_new_file:
        "Atención: hay disponible un nuevo modelo desde 18/09/2023.\n" +
        "          Descárguelo y úselo para cargar sus tríos.",
      triad_update:
        "Solo se actualizarán los miembros que no tengan trinomio. No se actualizará ningún dato (apellido, nombre o e-mail) de un usuario existente.",
      thanks: "No modifique ni elimine las columnas del modelo",
      contract_type_id: "ID tipos de contrato (columna AM)",
    },
    text: "Texto",
    dateToDisplay: "Fecha en que debe publicarse",
    emailContent: "Contenido e-mail",
    picture: "Imagen",
    subTitle: "Subtítulo",
    tag: "Etiqueta",
    video: "Vídeo",
  },
};
