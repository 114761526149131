export default {
  action_to_realize: "Acciones necesarias",
  sign_action: "Guardar y firmar",
  complete: "Rellenar",
  toComplete: "Pendiente",
  due_date: "Fecha límite",
  toSign: "Para firmar",
  description: "Descripción",
  yearProgression: "Progreso del año",
  progression: "Progreso",
  your_progression: "Su nivel de progreso",
  triad_progression: "Tasa de respuesta de trío",
  question: "Pregunta",
  incoming: "En espera",
  signature: "Firma",
  questionnaire: "Cuestionario",
  questionnaires: "Cuestionarios",
  yourAnswer: "Su respuesta: ",
  answerHere: "Responda aquí",
  commentHere: "Deje un comentario aquí",
  comment: "Comentar la respuesta",
  answerComment: "Responder al comentario",
  sendComment: "Publicar el comentario",
  sign: "Firmar",
  draft: "Guardar como borrador",
  sharedDraft: "Guardar y compartir",
  downloadPdf: "Descargar el cuestionario",
  downloadPdfModel: "Descargar el modelo",
  interviewDate: "Fecha de visita a empresa",
  youAnswered: "Ha respondido:",
  sendFile: "Enviar un archivo",
  sendDocument: "Enviar un documento",
  takePicture: "Hacer foto",
  fileSource: "Origen del archivo",
  yesSign: "Sí, firmo",
  questionnaire_remove_title: "Eliminar un cuestionario",
  questionnaire_remove_sub_title:
    "¿Confirma que quiere eliminar este cuestionario?",
  copy_questionnaire: "Copiar el cuestionario",
  questionnaire_copy_success: "Cuestionario copiado correctamente",
  questionnaire_removed_success: "Cuestionario eliminado correctamente",
  questionnaires_copied: "Versiones del cuestionario anterior",
  signMessage:
    "¿Confirma que quiere firmar el cuestionario? Después ya no podrá modificar sus respuestas.",
  row_add_success: "Línea añadida correctamente",
  comment_add_success: "Comentario añadido correctamente",
  deleteMessage: "¿Confirma que quiere eliminar esta línea?",
  download_report: "Descargar el informe",
  signatory: "Firmado por",
  signatories: "Firmantes",
  expected_signature: "Tiene que firmarse antes del",
  signed_on: "Firmado el",
  commented_by: "Comentado por",
  success: "Guardado correctamente",
  draftSuccess: "Borrador guardado correctamente",
  sharedSuccess: "Borrador compartido correctamente",
  signedSuccess: "Firmado correctamente",
  deletedQuestionnaire: "Este cuestionario se ha eliminado",
  addLine: "Añadir una fila",
  "Competence calculated by your answers to the questionnaires":
    "Competencia calculada según sus respuestas a los cuestionarios",
  open_from: "Disponible desde",
  no_crew_dates: "Sin fechas para el grupo",
  open_to: "al",
  marks: "Notas",
  block_sign:
    "El cuestionario solo se puede firmar después de que los otros miembros del trinomio lo validen.",
  no_mark: "Sin nota de momento",
  "Download dates": "Descargar las fechas",
  "Print all the questionnaires": "Imprimir todos los cuestionarios",
  answered: "ha respondido el",
  remove_answer_title: "Eliminación de una línea",
  remove_answer: "¿Seguro que quiere eliminar esta línea de respuesta?",
  to_be_completed_from: " Completar a partir de ",
  to: " al ",
  open_at: "Disponible el ",
  export_questionnaire: "Exportar el cuestionario",
  import_questionnaire: "Importar un cuestionario",
  no_questionnaire: ({ plural }) =>
    plural([
      "No se ha respondido ningún cuestionario para este año",
      "No se ha respondido ningún cuestionario para esta categoría",
    ]),
};
