export default {
  header: {
    dark: "Tema oscuro",
    light: "Tema claro",
    system: "Tema del navegador",
    notification: {
      empty: "Ningún procesamiento en curso...",
      waiting: "En espera de información sobre el procesamiento",
      list: "Ver todo el historial",
      historic: "Historial de procesamientos",
      download: "Descargar",
      file: "Su archivo",
    },
  },
  menu: "Menú",
  dashboard: {
    timeline: "Línea temporal",
    indicator: "Indicadores",
    indicators: {
      see_details: "Ver detalle de las tasas de respuesta",
      see_details_complete_triad: "Tasa de tríos completos",
      fill_rate: "Tasa de respuesta",
    },
    shared_docs: "Documentos compartidos",
    registration: "Inscripciones",
    welcome_to_studea: "¡Bienvenidos a su portal Studea!",
    your_actually_are: "Ahora mismo está",
    welcome_message: ({ named }) =>
      `¡Bienvenido/a, <strong>${named("name")}</strong> a su portal Studea!`,
    no_training_course_archived: "Ninguna promoción archivada",
    no_training_course_actived: "Ninguna promoción activa",
    no_triad: "Ningún trío",
    triad_search_placeholder: "Buscar",
    triad_members: "Miembros del trinomio",
    contract: {
      type: "Tipo de contrato :",
      company: "Empresa:",
      dates: "Fechas del contrato:",
      rupture_date: "Fecha de rescisión del contrato",
      siret: "Siret:",
      address: "Dirección :",
      from: "Del",
      to: "al",
      secondary: "secundario",
    },
    training: {
      triad_list: "Ver la lista de tríos",
    },
    archived_training_course: ({ plural, named }) =>
      plural([
        `Ver las ${named("label")}s archivadas`,
        `Ver los ${named("label")}s archivados`,
      ]),
    unarchived_training_course: ({ plural, named }) =>
      plural([
        `Ver las ${named("label")}s activas`,
        `Ver los ${named("label")}s activos`,
      ]),
    Download_the_booklet: "Descargar el cuaderno",
    Download_key_dates: "Descargar las fechas clave",
    session_lock: "Esta sesión todavía no está abierta para las selecciones.",
    skill_lock:
      "No puede modificar \nesta competencia, que se \ncalcula según sus respuestas a los \ncuestionarios o que no \ntiene asignada",
  },
};
