export default {
  studea_manager: {
    confirm_duplication: "¿Seguro que quiere duplicar estos datos?",
    data_duplicated_success: "Datos duplicados correctamente",
    delete_session_confirmation: "¿Confirma que quiere eliminar este periodo?",
    delete_referential_confirmation:
      "¿Confirma que quiere eliminar esta referencia?",
    navigation: {
      general: "General",
      modules: "Módulos",
      pilotage: "Dirección",
    },
    triadUser: {
      title: "usuario secundario",
    },
    moderation: {
      title: ({ plural }) => plural(["Denuncia", "Denuncias"]),
      censor: "Censurar",
      restore: "Anular la denuncia",
      show: "Ver",
      show_censor: "Ver los mensajes censurados",
      show_message: "Ver los mensajes para moderar",
      file: "Archivo",
      modal_title: "Conversación",
      alert_title: "Censurar un mensaje",
      alert_sub_title: "¿Quiere censurar o anular la denuncia de este mensaje?",
      censor_success: "Mensaje censurado correctamente",
    },
    home: {
      title: ({ plural }) =>
        plural(["Configuración de la ", "Configuración del "]),
      page_title: "Configuración",
    },
    questionnaire: {
      title: ({ plural }) => plural(["Cuestionario", "Cuestionarios"]),
      list: {
        title: "Configuración de los cuestionarios",
      },
      questionnaire_already_signed: "Este cuestionario ya se ha firmado",
      start_date: ({ named }) => `Fecha de acceso ${named("profile")} - del`,
      end_date: "al",

      finish: {
        title: "Finalizar mi configuración",
      },
      empty_title:
        "Para empezar, añada un título, una pregunta o una tabla de respuesta",
      add_question: "Añadir una pregunta",
      add_title: "Añadir un título",
      add_question_cluster: "Añadir una tabla de preguntas",
      required: "Obligatorio",
      question_settings: "Configuración de la pregunta",
      question_access: "Configuración de los accesos",
      cluster_settings: "Configuración de la tabla",
      remove_question: "Eliminar la pregunta",
      remove_cluster: "Eliminar la tabla",
      remove_title: "Eliminar el título",
      duplicate_cluster: "Duplicar la tabla",
      duplicate_question: "Duplicar la pregunta",
      tip: "Indicador de función",
      display_mode: "Tabla de respuesta",
      save: "Registrar",
      save_success: "Cuestionario guardado correctamente",
      save_error: "Se ha producido un error en el almacenamiento",
      missing_error: "Faltan derechos de acceso o títulos de las preguntas",
      informations: "Información",
      deadlines_reminders: "Fechas límite y recordatorios",
      advanced_parameter: "Configuración avanzada",
      company_visit: "Visita a la empresa",
      settings: "Parámetros",
      summary: "Resumen",
      add_column: "Añadir una columna",
      add_row: "Añadir una fila",
      row_settings: "¿Quién lo rellena?",
      skill_settings: "Gestionar las competencias relacionadas",
      access_missing: "Faltan derechos de acceso para esta pregunta",
      title_missing: "Debe introducir un título para esta pregunta",
      duplicate_row: "Duplicar la línea",
      remove_row: "Eliminar la línea",
      remove_column: "Eliminar la columna",
      empty_line: "Línea sin título",
      add_skill: "Añadir esta competencia",
      skill_category_name: "Categoría",
      skill_name: "Apellido",
      coefficient: "Coeficiente",
      empty_column: "Título de la columna",
      remove_field_skill: "Eliminación de la competencia asociada",
      field_skill_used: "Esta competencia ya está asociada a esta pregunta",
      remove_field_skill_sub:
        "¿Seguro que quiere eliminar la asociación de esta competencia con la pregunta?",
      untitled_column: "Columna sin título",
      remove_row_warning: "¿Confirma que quiere eliminar esta línea?",
      remove_column_warning: "¿Confirma que quiere eliminar esta columna?",
      untitled_questionnaire: "Formulario sin título",
      placeholder_description: "Añadir su descripción",
      access_profil: "Perfiles que responden a la pregunta",
      field_skill: "Competencias vinculadas a la pregunta",
      search_skill: "Buscar una competencia",
      opened_question_access: "Acceso a las preguntas abiertas",
      opened_questions: "Preguntas abiertas",
      opened_questions_type: "Tipos de preguntas abiertas",
      grid_title: "Título de la tabla",
      comments_enabled: "Comentarios autorizados",
      comments_label: "Título de los comentarios",
      comments_type: "Tipos de campos comentarios",
      one_line: "Respuesta breve",
      multi_line: "Zona de texto",
      maximal_mark: "Nota máxima",
      display_mark: "Usar las notas",
      display_radar: "Mostrar el radar",
      question_title: "Título de la pregunta",
      infobulle_content: "Contenido del mensaje informativo",
      question_comment: "Comentario",
      field_type_choose: "Seleccione un tipo",
      short_answer: "Respuesta breve",
      long_answer: "Respuesta larga",
      textarea: "Zona de texto",
      number: "Número",
      dropdown_list: "Lista desplegable",
      unique_choice: "Selección única",
      multiple_choices: "Selección múltiple",
      file: "Archivo",
      date: "Fecha",
      question: ({ plural }) => plural(["pregunta", "preguntas"]),
      date_time: "Fecha y hora",
      remove_question_warning: "¿Confirma que quiere eliminar esta pregunta?",
      option: "Opción",
      untitled_cluster: "Tabla sin título",
      untitled_title: "Título",
      title_label: "Título",
      remove_cluster_warning: "¿Confirma que quiere eliminar esta tabla?",
      value: "Valor",
      choose_option: "Seleccione una opción",
      questionnaire_settings: "Configuración del cuestionario",
      confirm: "Confirmar",
      cancel: "Cancelar",
      rights: "Derechos",
      notes_summary: "Notas y resumen",
      option_placeholder: "Opción",
      value_placeholder: "Nota",
      comment_placeholder: "Añadir un comentario",
      option_settings: "Configuración de opciones",
      columns: "Columnas",
      active: "Activo",
    },
    category: {
      title: ({ plural }) => plural(["Categoría", "Categorías"]),
      add: "Añadir una categoría",
    },
    webLink: {
      title: ({ plural }) => plural(["Enlace útil", "Enlaces útiles"]),
    },
    trainingCourse: {
      title: ({ plural }) => plural(["Promoción", "Promociones"]),
    },
    skill: {
      title: ({ plural }) => plural(["Competencia", "Competencias"]),
      list: {
        title: "Configuración de competencias",
        empty:
          "Todavía no hay competencias en esta formación. Cree una primera categoría para empezar",
        empty_only: "Todavía no hay competencias introducidas",
      },
      actions: {
        add: "Añadir una competencia",
        module_actions: "Configuración",
        skill_actions: "Gestionar las competencias",
        session_actions: "Gestionar los períodos",
        import_export_actions: "Importar/Exportar",
        reminder_actions: "Recordatorios",
      },
      name: "Apellido",
      level: "Nivel exigido",
      default: {
        not_acquired: "No adquirida",
        acquired: "Adquirida",
        expert: "Experto",
        being_acquired: "En curso de adquisición",
        insufficient_mastery: "Dominio insuficiente",
        partial_mastery: "Dominio parcial",
        average_mastery: "Dominio medio",
        good_mastery: "Dominio correcto",
        excellent_mastery: "Dominio excelente",
      },
    },
    skill_configuration: {
      title: "Configuración del módulo",
      success: "Configuración actualizada correctamente",
      use_admin: "¿Utilizar la configuración de administrador?",
      warning:
        "¡Atención! Debe terminar de configurar el módulo de competencias para poder integrar una referencia.",
    },
    skill_referential: {
      title: "Gestión de referencias de competencias",
      success: "Referencias actualizadas correctamente",
      warning:
        "¡Atención! Debe terminar de configurar el módulo de competencias para poder integrar una referencia.",
      empty_add: "Todavía no hay referencias. Haga clic en + para añadir una",
    },
    skill_generate: {
      title: "Gestionar un cuestionario a partir de las competencias",
      success: "Cuestionario generado correctamente",
    },
    skill_remove: {
      title: "Eliminar la referencia (sin los cuestionarios)",
      sub_title: "¿Confirma que quiere eliminar la referencia?",
      success: "Referencia eliminada correctamente",
    },
    skill_import: {
      title: "Exportar sus competencias",
      success: "Competencias importadas correctamente",
    },
    skill_import_france_competence: {
      title: "Importar la referencia de competencias de Francia",
      confirm:
        "¿Seguro que quiere cargar la referencia de France Compétences en esta formación?",
      success: "Referencia importada correctamente",
    },
    skill_export: {
      title: "Exportar las competencias",
      success: "Competencias exportadas correctamente",
    },
    skill_export_taught: {
      title: "Exportar las materias",
      success: "Materias exportadas correctamente",
    },
    skillSession: {
      gestion: "Gestión de períodos de competencias",
      list: {
        title: "Configuración de períodos",
        empty: "Todavía no hay ningún periodo. Haga clic en + para añadir uno",
      },
      title: ({ plural }) => plural(["Periodo", "Períodos"]),
    },
    skillReferential: {
      title: ({ plural }) => plural(["Referencia", "Referencias"]),
      use: "Utilizar una referencia",
    },
    skillCategory: {
      title: ({ plural }) => plural(["Categoría", "Categorías"]),
    },
    skin: {
      title: ({ plural }) => plural(["Diseño", "Diseños"]),
      list: {
        title: "Diseño",
      },
    },
    gradebook: {
      title: ({ plural }) => plural(["Boletín de notas", "Boletines de notas"]),
    },
    triad: {
      title: ({ plural }) => plural(["Trinomio", "Trinomios"]),
      list: {
        name: "Apellido",
        apprentice_name: "Nombre del alumno",
        tutor_name: "Nombre del tutor pedagógico",
        map_name: "Nombre del tutor de empresa",
        gestionnary_name: "Nombre del gestor",
      },
      tutor: {
        title: ({ plural }) =>
          plural(["Tutor pedagógico", "Tutores pedagógicos"]),
      },
      map: {
        title: ({ plural }) =>
          plural(["Tutor de empresa", "Tutores de empresa"]),
      },
      questionnaire_triad_already_signed:
        "Atención: hay firmas de \ncuestionarios asociadas \na este trío",
      usurp: {
        title: "Representar a",
      },
      login: {
        title: "Gestionar el envío de identificadores",
      },
      delete_all: {
        tutor: "Eliminar todos los tutores pedagógicos",
        map: "Eliminar todos los tutores de empresa",
      },
      delete: {
        tutor: "Eliminar este tutor pedagógico",
        map: "Eliminar este tutor de empresa",
        legal_responsible: "Eliminar este responsable legal",
      },
      delete_member: {
        title: "Miembro del trío",
      },
      delete_all_tutor: {
        title: "todos los tutores pedagógicos",
      },
      delete_all_map: {
        title: "todos los tutores de empresa",
      },
      edit: {
        legal_responsible: "Modificar este responsable legal",
        tutor: "Modificar este tutor pedagógico",
      },
      contract: {
        title: "Gestión de contratos",
      },
      complete: {
        title: "Añadir un miembro al trío",
      },
      actions: {
        title: "Acciones",
      },
      add_test: "trío de prueba",
      add_test_success: "Trío de prueba añadido correctamente",
      send_user_infos: "Gestionar el envío de identificadores",
      send_welcome_email:
        "Enviar el correo electrónico de bienvenida con las credenciales",
      send_welcome_email_success:
        "Correo(s) de bienvenida enviado(s) correctamente",
      reset_password:
        "Restablecer la contraseña y volver a enviar el identificador",
      reset_password_success:
        "Correo(s) de restablecimiento de contraseña enviado(s) correctamente",
      send_user_infos_message:
        "Seleccione los usuarios y haga clic en el tipo de correo electrónico que les quiere enviar.",
      add_secondary_user: "Añadir un usuario secundario para este trío",
      add_secondary_user_success: "Usuario secundario añadido correctamente",
      remove_secondary_user: "Usuario secundario eliminado correctamente",
    },
    studeaUser: {
      title: ({ plural }) => plural(["Usuario", "Usuarios"]),
    },
    user: {
      title: ({ plural }) => plural(["Usuario", "Usuarios"]),
    },
    crew: {
      title: ({ plural }) => plural(["Grupo", "Grupos"]),
      cannot_remove: "No puede eliminar un grupo que tenga tríos",
    },
    contract: {
      title: ({ plural }) => plural(["Contrato", "Contratos"]),
      empty: "Este trío no tiene ningún contrato",
      dates: "Fechas del contrato",
      company: "Empresa",
      duration: "Duración",
      address: "Dirección",
      map: "Mentor",
      manager: "Gestor",
      contrat_type: "Tipo de contrato",
      rupture_date: "Fecha de rescisión del contrato",
      contract_rupture_date: "Contrato rescindido en: ",
      rupture_reason: "Motivo de la rescisión del contrato",
      months: "meses",
      also_tutor: "Es tutor de este trío",
      incident_sheet: "Hay una ficha de incidente en curso en este trío",
    },
    template: {
      title: ({ plural }) => plural(["Plantilla", "Plantilles"]),
    },
    lesson: {
      title: ({ plural }) => plural(["Curso", "Curso"]),
    },
    skillType: {
      title: ({ plural }) =>
        plural([
          "Tipo de competencia",
          "Tipos de competencias",
          "Tipo de competencias",
        ]),
    },
    subject: {
      title: ({ plural }) => plural(["Asignatura", "Asignaturas"]),
    },
    unit: {
      title: ({ plural }) => plural(["Unidad", "Unidades"]),
    },
    planning: {
      title: ({ plural }) => plural(["Evento", "Eventos"]),
      removeSelected: {
        title: "Eliminar los eventos seleccionados",
        sub_title: "¿Confirma que quiere eliminar estos eventos?",
        sucess: "Eventos eliminados correctamente",
      },
    },
    trainingCourseModuleTriad: {
      title: ({ plural }) => plural(["Módulo", "Módulos"]),
    },
    absence: {
      title: ({ plural }) => plural(["Ausente", "Ausencias"]),
      list: {
        title: "Asistencia",
      },
      config: {
        title: "Configuración",
        success: "Configuración modificada correctamente",
      },
      export: {
        full_list: "Lista completa de ausencias",
        apprentice_list: "Lista de ausencias por ",
        excel: "(Excel)",
        pdf: "(PDF)",
        state: "Estado de las ausencias (planificación)",
      },
    },
    module: {
      active: {
        success: "Módulo activado correctamente",
      },
      unactive: {
        success: "Módulo desactivado correctamente",
      },
    },
    registration: {
      title: ({ plural }) => plural(["Inscripción", "Inscripciones"]),
      list: {
        title: "Inscripciones",
      },
    },
    notepad: {
      title: ({ plural }) => plural(["Bloc de notas", "Blocs-notas"]),
    },
    note: {
      title: ({ plural }) => plural(["Nota", "Notas"]),
    },
    document: {
      title: ({ plural }) => plural(["Documento", "Documentos"]),
      pedagogic_documents: "Documentos pedagógicos",
      general_documents: "Documentos generales",
      shared_documents: "Documentos compartidos",
      shared_document_add: "Añadir documento compartido",
      shared_document_edit: "Modificar un documento compartido",
      shared_document_list: "Documentos compartidos",
      shared_document_info:
        "Este documento está compartido. Para modificarlo, \nvaya a la lista de documentos compartidos",
    },
    "document/shared": {
      title: ({ plural }) =>
        plural(["Documento compartido", "Documentos compartidos"]),
    },
    incident_sheet: {
      title: ({ plural }) => plural("Ficha de incidente", "Fichas de control"),
      list: "Fichas de control",
      add_comment: "Añadir un comentario",
      close: "Cerrar",
      close_confirmation: "¿Seguro que quiere cerrar esta ficha de control?",
      open: "Abrir",
      open_confirmation: "¿Seguro que quiere reabrir esta ficha de control?",
      delete: "Eliminar",
      delete_confirmation: "¿Seguro que quiere eliminar esta ficha de control?",
      in_progress: "En curso",
      closed: "Cerrado",
      search: "Buscar una ficha de control",
    },
    currently: "actualmente",
    documentExchange: {
      list: {
        title: "Intercambio de documentos",
      },
    },
    messaging: {
      list: {
        title: "Mensajes",
      },
    },
    dashboard: {
      list: {
        title: "Tablero de control",
      },
      signature_cancel_confirmation: "¿Confirma que quiere cancelar la firma?",
      filter: "Filtrar",
      delete_filters: "Eliminar los filtros",
      questionnaire: {
        unlocked_confirmation: "¿Quiere desbloquear este cuestionario?",
        locked_confirmation: "¿Quiere bloquear este cuestionario?",
        unlocked_all_confirmation:
          "¿Quiere desbloquear todos los cuestionarios de esta columna?",
        unlock_all_questionnaires:
          "Desbloquear todos los cuestionarios de esta columna",
        unlock_questionnaire: "Desbloquear este cuestionario",
        opening_date_on: "Fecha de inscripción en",
        lock_questionnaire: "Bloquear este cuestionario",
        cancel_signature: "Anular esta firma",
        send_email_reminder: "Recordar por correo electrónico",
        send_email_reminder_confirmation:
          "¿Seguro que quiere enviar un recordatorio por correo electrónico?",
        send_email_reminder_success: "Correo electrónico enviado correctamente",
        success_all_unlock: "Cuestionarios desbloqueados correctamente",
        success_unlock: "Cuestionario desbloqueado correctamente",
        success_lock: "Cuestionario bloqueado correctamente",
        success_cancel_signature: "Firma anulada correctamente",
      },
      mail_relance: "Recordatorios por correo electrónico",
      email_reminder: "Recordar por correo electrónico",
      export: {
        title: "Exportar",
        all_promotion_replies: "Exportar todas las respuestas de la promoción",
        visit_date: "Exportar las fechas de visita a la empresa",
        dashboard_excel: "Exportar el panel de control en formato Excel",
        questionnaires_pdf: "Exportar los cuestionarios",
      },
      roles: {
        alternate: "Alumno en prácticas",
        educational_tutor: "Tutor pedagógico",
        company_tutor: "Tutor de empresa",
        lea_manager: "Responsable de léa",
      },
    },
    gradeBookDashboard: {
      list: {
        title: "Tablero de notas",
      },
      export_dashboard_excel: "Exportar tabla de notas en formato Excel",
    },
    skillDashboard: {
      list: {
        title: "Tablero de control de competencias",
      },
      export_dashboard_excel: "Exportar tabla de competencias en formato Excel",
      skill: {
        acquired: "Adquirida",
        in_progress: "En curso",
        not_acquired: "No adquirida",
      },
    },
    export: {
      list: {
        title: "Exportaciones",
      },
    },
    mail: {
      list: {
        title: "Seguimiento de correos",
      },
    },
    history: {
      list: {
        title: "Historial de conexiones",
      },
    },
    assiduite: "Gestión de asistencia",
    subscriptions_dashboard: "Tablero de control de inscripciones",
    import: {
      modele: "Modelo de archivo para descargar",
      triad_new_file:
        "Atención: hay disponible un nuevo modelo desde 18/09/2023.\n" +
        "          Descárguelo y úselo para cargar sus tríos.",
      triad_update:
        "Solo se actualizarán los miembros que no tengan trinomio. No se actualizará ningún dato (apellido, nombre o e-mail) de un usuario existente.",
      thanks: "No modifique ni elimine las columnas del modelo",
    },
    download_file_here: "Descargar el archivo AQUÍ",
    session: {
      from: "Del",
      to: "al",
    },
    user_never_connected: "Este usuario no se ha conectado nunca",
    welcome_mail_send: ({ named }) =>
      `Correo de bienvenida enviado ${named("number")} veces`,
    reset_password_mail_send: ({ named }) =>
      `Correo de restablecimiento enviado ${named("number")} veces`,
    see_last_mail_content:
      "Ver el contenido del último correo electrónico enviado",
    duplication: "Duplicación de datos",
    statistic: {
      title: ({ plural }) => plural(["Estadística", "Estadísticas"]),
      shared_documents: "Tríos incompletos",
    },
  },
  add_session: "Añadir un periodo",
};
